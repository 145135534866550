<template>
  <BundledDBEditor v-if="template" object_function="create"
                          object_fq_name="cntl.mgr"
                          :modal_id="modal_id"
                          :template="template"
                          :presets="presets"
                          :prepend="false"
                          :object_title="$t('views.cntl.mgr.create_svc_account')"
                          :non_optionals_order="['svc_id', 'description', 'allow_data_manipulation', 'ou_short_name', 'group_name']"
  />
</template>

<script>
import BundledDBEditor from '@/components/db-editor/BundledDBEditor.vue'
import Axios from 'axios'

export default {
  name: 'CreateSVCAccount',
  components: { BundledDBEditor },
  async created() {
    this.template = (await Axios.get('/bundled_json_templates/svc_account_create.json')).data
  },
  computed: {
    presets() {
      return {
        is_svc: true
      }
    },
  },
  data() {
    return {
      template: null,
    }
  },
  props: {
    modal_id: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>
