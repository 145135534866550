// -- AUTOGENERATED --

// eslint-disable-next-line no-unused-vars
import APIUtils from '@/util/apiutil'

import Axios from 'axios'
import NETVSConfig from '@/../netvs.config'

/* eslint-disable camelcase */
/* eslint-disable quote-props */
/* eslint-disable object-curly-spacing */
/* eslint-disable array-bracket-spacing */
/* eslint-disable object-shorthand */
export default {

  bulk_deleteParamsList () {
    return {'old': [ 'mgr_login_name'], 'new': [ ]}
  },
  bulk_delete (config, {mgr_login_name_old}) {
    const params = { 'new': { }, 'old': { 'mgr_login_name': mgr_login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/bulk_delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  bulk_updateParamsList () {
    return {'old': [ 'group_name', 'ou_short_name', 'mgr_login_name', 'group_name_list', 'mgr_login_name_list'], 'new': [ 'ou_short_name', 'group_name_list', 'mgr_login_name_list']}
  },
  bulk_update (config, {group_name_old, ou_short_name_old, ou_short_name_new, mgr_login_name_old, group_name_list_old, group_name_list_new, mgr_login_name_list_old, mgr_login_name_list_new}) {
    const params = { 'new': { 'ou_short_name': ou_short_name_new, 'group_name_list': group_name_list_new, 'mgr_login_name_list': mgr_login_name_list_new}, 'old': { 'group_name': group_name_old, 'ou_short_name': ou_short_name_old, 'mgr_login_name': mgr_login_name_old, 'group_name_list': group_name_list_old, 'mgr_login_name_list': mgr_login_name_list_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/bulk_update`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  copyParamsList () {
    return {'old': [ 'copy_mgr_login_name'], 'new': [ 'copy_mgr_login_name']}
  },
  copy (config, {copy_mgr_login_name_old, copy_mgr_login_name_new}) {
    const params = { 'new': { 'copy_mgr_login_name': copy_mgr_login_name_new}, 'old': { 'copy_mgr_login_name': copy_mgr_login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/copy`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  createParamsList () {
    return {'old': [ ], 'new': [ 'group_name', 'mgr_login_name']}
  },
  create (config, {group_name_new, mgr_login_name_new}) {
    const params = { 'new': { 'group_name': group_name_new, 'mgr_login_name': mgr_login_name_new}, 'old': { }}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/create`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  deleteParamsList () {
    return {'old': [ 'group_name', 'mgr_login_name'], 'new': [ ]}
  },
  delete (config, {group_name_old, mgr_login_name_old}) {
    const params = { 'new': { }, 'old': { 'group_name': group_name_old, 'mgr_login_name': mgr_login_name_old}}
    // TODO: Return ta-object instead
    return Axios.post(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/delete`, params, (config || {}).netdb_axios_config)
  // eslint-disable-next-line comma-dangle
  },
  list (config, { gpk, is_own, gpk_list, fetch_limit, fetch_offset, group_name_list, filter_params_dict, mgr_login_name_list, sorting_params_list }) {
    const params = APIUtils.denullify_dict({ 'gpk': gpk, 'is_own': is_own, 'gpk_list': (gpk_list === null) ? null : JSON.stringify(gpk_list), 'fetch_limit': fetch_limit, 'fetch_offset': fetch_offset, 'group_name_list': (group_name_list === null) ? null : JSON.stringify(group_name_list), 'filter_params_dict': filter_params_dict, 'mgr_login_name_list': (mgr_login_name_list === null) ? null : JSON.stringify(mgr_login_name_list), 'sorting_params_list': (sorting_params_list === null) ? null : JSON.stringify(sorting_params_list)})
    const cnf = {}
    Object.assign(cnf, (config || {}).netdb_axios_config)
    cnf.params = params
    return Axios.get(`${NETVSConfig.NETDB_API_BASE_URL}/${NETVSConfig.NETDB_API_VERSION}/cntl/mgr2group/list`, cnf)
  // eslint-disable-next-line comma-dangle
  },
}
