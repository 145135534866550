<template>
  <b-modal size="xl" :ok-title="$t('system.close')" :title="$t('system.event_log') + ': ' + title"
           :ok-only="true" :id="modal_id" @show="fetchData()">
    <b-form @submit="fetchData">
      <b-input-group :prepend="$t('components.ev_log_viewer.transaction_number')" class="shadow">
        <b-form-input type="number" v-model="top_n"/>
        <b-input-group-append>
          <b-button type="submit" variant="success" @click="fetchData">{{ $t('system.refresh') }}</b-button>
        </b-input-group-append>
      </b-input-group>
      <b-input-group :prepend="$t('components.ev_log_viewer.limit_date')" class="mt-3 shadow">
        <b-form-datepicker v-model="filter.from_date" :label-no-date-selected="$t('system.datepicker.no_date_selected')"
                           :label-help="$t('system.datepicker.arrow_key_support')" :locale="$i18n.locale"
                           :label-current-month="$t('system.datepicker.current_month')"
                           :label-next-month="$t('system.datepicker.next_month')"
                           :label-next-year="$t('system.datepicker.next_year')"
                           :label-prev-month="$t('system.datepicker.previous_month')"
                           :label-prev-year="$t('system.datepicker.previous_year')"
                           :placeholder="$t('components.ev_log_viewer.from')"
                           today-button reset-button :label-today-button="$t('system.datepicker.select_today')"
                           :label-reset-button="$t('components.ev_log_viewer.delete_limit')"/>
        <b-form-datepicker v-model="filter.to_date" :label-no-date-selected="$t('system.datepicker.no_date_selected')"
                           :label-help="$t('system.datepicker.arrow_key_support')" :locale="$i18n.locale"
                           :label-current-month="$t('system.datepicker.current_month')"
                           :label-next-month="$t('system.datepicker.next_month')"
                           :label-next-year="$t('system.datepicker.next_year')"
                           :label-prev-month="$t('system.datepicker.previous_month')"
                           :label-prev-year="$t('system.datepicker.previous_year')"
                           :placeholder="$t('components.ev_log_viewer.until')"
                           today-button reset-button :label-today-button="$t('system.datepicker.select_today')"
                           :label-reset-button="$t('components.ev_log_viewer.delete_limit')"/>
      </b-input-group>
      <FilterInput v-model="filter.text"/>
    </b-form>
    <Loading :data="records">
      <b-table :responsive="true" striped :items="records" :fields="table_fields" :filter="filter"
               :filter-function="filterRecords" v-if="records && records.length > 0">
        <template v-slot:cell(data)="data">
          <span class="pre-wrap">{{ data.item.data }}</span>
        </template>
        <template v-slot:cell(type)="data">
          <template v-if="record_types[data.item.type]">
            {{ $t_netdb(record_types[data.item.type].name) }}
            {{ $t_netdb(record_types[data.item.type].action) }}
          </template>
          <template v-else>
            {{ $t('components.ev_log_viewer.unkown_type') }} {{ ($store.state.expert || $store.state.developer) ? ('(' + data.item.type + ')') : '' }}
          </template>
        </template>
      </b-table>
      <template v-if="records && records.length === 0">
        {{ $t('components.ev_log_viewer.no_records') }}
      </template>
    </Loading>
  </b-modal>
</template>

<script>
import EVLogService from '@/api-services/evlog.service'
import Loading from '@/components/Loading'
import apiutil from '@/util/apiutil'
import FilterInput from '@/components/FilterInput'
import '@/util/colorutil'
import dateutil from '../util/dateutil'

export default {
  name: 'EVLogViewer',
  components: { Loading, FilterInput },
  computed: {
    table_fields() {
      return [
        {
          key: 'ta',
          label: '',
          tdAttr: this.ta_color
        },
        {
          key: 'data',
          label: this.$t('system.description')
        },
        {
          key: 'type',
          label: this.$t('system.action_type')
        },
        {
          key: 'ta_timestamp',
          label: this.$t('system.time'),
          sortable: true,
          formatter: this.formatDate
        },
        {
          key: 'mgr_login_name',
          label: this.$t('system.account'),
          sortable: true
        }
      ]
    }
  },
  data() {
    return {
      records: null,
      record_types: {},
      top_n: 25,
      filter: { text: '', from_date: '', to_date: '' }
    }
  },
  props: {
    title: {
      defaut() {
        return this.ref_obj_fq
      }
    },
    modal_id: {
      required: true
    },
    ref_obj_fq: {
      required: true
    },
    refobj_id_field: {
      default() {
        return 'gpk'
      }
    },
    refobj_id_value: {
      required: true
    }
  },
  methods: {
    async fetchData(ev) {
      if (ev) {
        ev.preventDefault()
      }
      this.records = null
      const res = await EVLogService.getByFK(this.$store.state, this.ref_obj_fq, this.refobj_id_field, this.refobj_id_value, parseInt(this.top_n))
      this.records = res.data.record_list
      this.record_types = apiutil.dict_by_value_of_array(res.data.record_type_list, 'short_name')
    },
    ta_color(value, key, item, type) {
      return {
        title: this.$t('components.ev_log_viewer.actions_one_transaction_same_color'),
        style: 'background: ' + item.ta_timestamp.toHSL({ lit: [30, 40] })
      }
    },
    formatDate(value, key, item) {
      if (value == null) {
        return this.$t('system.n/a')
      }
      return dateutil.format_date(new Date(Date.parse(value)))
    },
    filterRecords(row, filter) {
      if (!row.data) return false
      if (row.data.includes(filter.text) || row.type.includes(filter.text) || row.ta_timestamp.includes(filter.text) || (row.mgr_login_name != null && row.mgr_login_name.includes(filter.text))) {
        if (filter.from_date !== '' && (Date.parse(row.ta_timestamp) < Date.parse(filter.from_date))) {
          return false
        }
        if (filter.to_date !== '' && (Date.parse(row.ta_timestamp) > Date.parse(filter.to_date))) {
          return false
        }
        return true
      } else {
        return false
      }
    }
  }
}
</script>

<style scoped>
.pre-wrap {
  white-space: pre-wrap;
}
</style>
