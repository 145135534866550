import TransactionService from './transaction.service'

export default {
  list (config, login_name = undefined) {
    const ta = [
      { name: 'cntl.mgr.list', old: { is_svc: true, login_name: login_name } }, // Accounts
      { name: 'cntl.mgr2role.list', inner_join_ref: { mgr_list: 'default' } }, // Mgr2Role
      { name: 'cntl.role.list', old: { is_role: true, is_directly_assigned: true, sorting_params_list: ['position'] } }, // Roles
      { name: 'cntl.mgr2group.list', inner_join_ref: { mgr_list: 'default' } }, // Groups
      { name: 'cntl.group.list', inner_join_ref: { mgr2group_list: 'default' } }, // Groups
      { name: 'cntl.mgr2ou.list', inner_join_ref: { mgr_list: 'default' } } // OU
    ]
    return TransactionService.execute(config, ta)
  }
}
