<template>
  <b-card no-body class="my-4 shadow" :class="account.is_sub ? 'mr-5 ml-5' : ''">
    <template v-slot:header>
      <b-row>
        <b-col lg="4">
          <h4>
            {{ account.login_name }}
          </h4>
          <p class="text-muted mb-0">{{ $t('components.svc_card.svc_name') }}</p>
          <b-badge v-if="account.is_svc" class="mr-1" variant="warning"
                   :title="$tc('components.svc_card.svc_account', 1)">
            <netvs-icon icon="svc_account"></netvs-icon>
            {{ $t('components.svc_card.svc') }}
          </b-badge>
          <b-badge v-if="account.is_sub" class="mr-1" variant="danger"
                   :title="$tc('components.svc_card.sub_account', 1)">{{ $t('components.svc_card.sub') }}
          </b-badge>
          <b-badge v-if="!account.allow_data_manipulation" class="mr-1" variant="info"
                   :title="$t('components.svc_card.read_only_description')">
            <netvs-icon icon="eye"></netvs-icon>
            {{ $t('components.svc_card.read_only') }}
          </b-badge>
        </b-col>
        <b-col :lg="2">
          <template v-if="account.svc_id">
            <p>{{ account.svc_id }}</p>
            <p class="text-muted">{{ $t('components.svc_card.svc_id') }}</p>
          </template>
          <template v-else-if="account.sub_id">
            <p>{{ account.sub_id }}</p>
            <p class="text-muted">{{ $t('components.svc_card.sub_id') }}</p>
          </template>
        </b-col>
        <b-col :lg="4">
          <template v-if="account.description && account.description.length > 0">
            <p>{{ account.description }}</p>
            <p class="text-muted">{{ $t('system.description') }}</p>
          </template>
        </b-col>
        <b-col lg="2">
          <b-button-group class="d-flex">
            <b-button v-if="can_impersonate || $store.state.developer"
                      :variant="(can_impersonate) ? 'outline-secondary' : 'outline-danger'"
                      :id="'impersonate-' + account.login_name + _uid"
                      @click="$store.commit('updateImpersonatingUser', account.login_name); $store.commit('reloadRouterComp')">
              <netvs-icon icon="impersonate"></netvs-icon>
            </b-button>
            <b-tooltip v-if="can_impersonate || $store.state.developer"
                       :target="'impersonate-' + account.login_name + _uid" triggers="hover" variant="secondary"
                       placement="bottom">
              {{
                can_impersonate ? $t('components.svc_card.impersonate') : $t('components.svc_card.impersonate_rollback')
              }}
            </b-tooltip>
            <b-button variant="outline-secondary" :id="'show-evlog-group-' + account.login_name + _uid"
                      @click="showEVLog(account)" v-if="showEVLog !== undefined">
              <netvs-icon icon="evlog"></netvs-icon>
            </b-button>
            <b-tooltip :target="'show-evlog-group-' +  account.login_name + _uid" triggers="hover"
                       variant="secondary" placement="bottom">
              {{ $t('system.event_log') }}
            </b-tooltip>
            <b-button @click="updateMgr()" variant="outline-primary"
                      :id="'button-edit-svc-' + account.login_name + _uid">
              <netvs-icon icon="edit"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-edit-svc-' + account.login_name + _uid" triggers="hover"
                       variant="primary" placement="bottom">
              {{ $t('components.svc_card.edit_svc') }}
            </b-tooltip>
            <b-button @click="deleteMgr(account)" variant="outline-danger"
                      :id="'button-delete-svc-' + account.login_name + _uid">
              <netvs-icon icon="delete"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-delete-svc-' + account.login_name + _uid" triggers="hover"
                       variant="danger" placement="bottom">
              {{ $t('components.svc_card.delete_svc') }}
            </b-tooltip>
          </b-button-group>
        </b-col>
      </b-row>
    </template>
    <b-button block squared variant="outline-secondary" v-if="!account.is_sub"
              v-b-toggle="account.login_name + '-ous-collapse'">
      <netvs-icon icon="groups"></netvs-icon>
      {{ $tc('system.organizational_unit', 2) }}
      <b-badge pill>{{
          mgr_ous.length
        }}
      </b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse v-if="!account.is_sub" :id="account.login_name + '-ous-collapse'">
      <b-alert show class="m-3" dismissible>
        <NETVSIcon icon="info" />
        {{ $t('components.svc_card.ou_svc_info') }}
      </b-alert>
      <b-table :items="mgr_ous" :fields="ous_list_fields">
        <template v-slot:cell(ou_short_name)="data">
          <b-link :to="`/org/ou/${data.item.ou_short_name}`">{{ data.item.ou_short_name }}</b-link>
        </template>
        <template v-slot:head(actions)>
          <b-button block variant="outline-success"
                    :id="'button-assign-mgr-' +  account.login_name + _uid" @click="createMgr2OU()">
            <netvs-icon icon="create"></netvs-icon>
          </b-button>
          <b-tooltip :target="'button-assign-mgr-' +  account.login_name  + _uid" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.svc_card.assign_ou') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button v-if="mgr_ous.length > 1 || $store.state.expert"
                      @click="deleteOUMgrItem({ mgr_login_name: account.login_name, ou_short_name: data.item.ou_short_name })"
                      variant="outline-danger"
                      :id="'button-unassign-mgr-ou-' +  account.login_name + _uid">
              <netvs-icon icon="unlink"></netvs-icon>
              <NetvsExpertMarker v-if="mgr_ous.length <= 1 && $store.state.expert" class="ml-1"></NetvsExpertMarker>
            </b-button>
            <b-tooltip :target="'button-unassign-mgr-ou-' +  account.login_name  + _uid" triggers="hover"
                       variant="danger" placement="left">
              {{ $t('components.svc_card.remove_ou_assignment') }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-collapse>
    <b-button block squared variant="outline-secondary" v-b-toggle="account.login_name + '-groups-collapse'">
      <netvs-icon icon="groups"></netvs-icon>
      {{ $tc('system.group', 2) }}
      <b-badge pill>
        {{
          own_groups.length
        }}
      </b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse :id="account.login_name + '-groups-collapse'">
      <b-table :items="own_groups" :fields="group_list_fields">
        <template v-slot:cell(name)="data">
          <b-link :to="`/cntl/groups/${data.item.name}`">{{ data.item.name }}</b-link>
        </template>
        <template v-slot:cell(ou_short_name)="data">
          <b-link :to="`/org/ou/${data.item.ou_short_name}`">{{ data.item.ou_short_name }}</b-link>
        </template>
        <template v-slot:cell(description)="data">
          {{ data.item.description }}
        </template>
        <template v-slot:head(actions)>
          <b-button block variant="outline-success"
                    :id="'button-assign-mgr-group-' +  account.login_name + _uid" @click="createMgr2Group()">
            <netvs-icon icon="create"></netvs-icon>
          </b-button>
          <b-tooltip :target="'button-assign-mgr-group-' +  account.login_name  + _uid" triggers="hover"
                     variant="success" placement="left">
            {{ $t('components.svc_card.assign_group') }}
          </b-tooltip>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button-group class="d-flex">
            <b-button @click="deleteGroupMgrItem({ mgr_login_name: account.login_name, group_name: data.item.name })"
                      variant="outline-danger"
                      :id="'button-unassign-mgr-' +  account.login_name + _uid">
              <netvs-icon icon="unlink"></netvs-icon>
            </b-button>
            <b-tooltip :target="'button-unassign-mgr-' +  account.login_name  + _uid" triggers="hover"
                       variant="danger" placement="left">
              {{ $t('components.svc_card.remove_assignment') }}
            </b-tooltip>
          </b-button-group>
        </template>
      </b-table>
    </b-collapse>
    <b-button v-if="!account.is_sub" block squared variant="outline-secondary"
              v-b-toggle="account.login_name + '-sub-accounts-collapse'">
      <netvs-icon icon="subaccounts"></netvs-icon>
      {{ $tc('components.svc_card.sub_account', 2) }}
      <b-badge pill>
        {{ mgr_sub_accounts.length }}
      </b-badge>
      <br/>
      <netvs-icon class="collapse-icon" icon="collapse"></netvs-icon>
    </b-button>
    <b-collapse v-if="!account.is_sub" :id="account.login_name + '-sub-accounts-collapse'">
      <SVCAccountCard
          :account="sub_account"
          :showEVLog="showEVLog"
          :get_groups_of_mgr="get_groups_of_mgr"
          :get_ous_of_mgr="get_ous_of_mgr"
          :get_sub_account_of_mgr="get_sub_account_of_mgr"
          :check_can_impersonate="check_can_impersonate"
          v-for="sub_account in mgr_sub_accounts" v-bind:key="sub_account.login_name + '_sub_uid'"
      ></SVCAccountCard>
    </b-collapse>
    <APIObjectDBEditor :modal_id="'assign_to_group_' + account.gpk" :object_function="db_editor_function"
                       :object_fq_name="db_editor_object_fq_name"
                       :presets="db_editor_presets"
                       :non_optionals_order="db_editor_non_optionals_order"
                       :object_title="db_editor_object_fq_name != null ? db_editor_object_fq_name[db_editor_object_fq_name] : ''"
                       :old_data="db_editor_old_data">
    </APIObjectDBEditor>
  </b-card>
</template>

<script>

import transactionutil from '@/util/transactionutil'
import Mgr2GroupService from '@/api-services.gen/cntl.mgr2group'
import CntlMgr2ou from '@/api-services.gen/cntl.mgr2ou'
import NetvsExpertMarker from '@/components/NETVSExpertMarker.vue'
import CntlMgr from '@/api-services.gen/cntl.mgr'
import APIObjectDBEditor from '@/components/db-editor/APIObjectDBEditor.vue'
import NETVSIcon from '@/icons/NETVSIcon.vue'

export default {
  name: 'SVCAccountCard',
  components: {APIObjectDBEditor, NetvsExpertMarker, NETVSIcon},
  props: {
    account: {
      type: Object,
      required: true
    },
    showEVLog: {
      type: Function,
      required: false,
      default: undefined
    },
    get_groups_of_mgr: {
      type: Function,
      require: true
    },
    get_ous_of_mgr: {
      type: Function,
      require: true
    },
    get_sub_account_of_mgr: {
      type: Function,
      require: true
    },
    check_can_impersonate: {
      type: Function,
      require: true
    }
  },
  data() {
    return {
      db_editor_presets: {},
      db_editor_object_fq_name: null,
      db_editor_function: 'create',
      default_order: ['group_name', 'parent_name', 'description', 'do_copy_assignments', 'mgr_login_name', 'bcd_name', 'fqdn_value'],
      db_editor_non_optionals_order: ['group_name', 'parent_name', 'description', 'do_copy_assignments', 'mgr_login_name', 'bcd_name', 'fqdn_value', 'ou_short_name'],
      db_editor_old_data: {}
    }
  },
  methods: {
    deleteGroupMgrItem(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr2group', Mgr2GroupService.deleteParamsList(), item, item.mgr_login_name + ' ' + this.$t('views.cntl.groups.from_group') + ' ' + item.group_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.state.show_sidebar_right = true
      }
    },
    deleteOUMgrItem(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr2ou', CntlMgr2ou.deleteParamsList(), item, item.mgr_login_name + ' ' + this.$t('views.cntl.ou.from_ou') + ' ' + item.ou_short_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.state.show_sidebar_right = true
      }
    },
    deleteMgr(item) {
      const ta = transactionutil.generateDeleteElement('cntl.mgr', CntlMgr.deleteParamsList(), item, this.$t('system.delete') + ': ' + item.login_name)
      this.$store.commit('addTransactionElement', ta)
      this.$emit('commited', ta)
      if (!this.isMobile() && !this.$store.state.show_sidebar_right) {
        this.$store.state.show_sidebar_right = true
      }
    },
    updateMgr() {
      this.db_editor_function = 'update'
      this.db_editor_old_data = this.account
      this.db_editor_presets = this.account
      this.db_editor_object_fq_name = 'cntl.mgr'
      this.db_editor_non_optionals_order = this.default_order
      if (!this.account.is_sub) {
        this.db_editor_non_optionals_order = ['svc_id', 'description', 'allow_data_manipulation']
      } else {
        this.db_editor_non_optionals_order = ['sub_id', 'description', 'allow_data_manipulation']
      }
      this.$root.$emit('bv::show::modal', 'assign_to_group_' + this.account.gpk)
    },
    createMgr2Group() {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = {mgr_login_name: this.account.login_name}
      this.db_editor_object_fq_name = 'cntl.mgr2group'
      this.db_editor_non_optionals_order = this.default_order
      this.$root.$emit('bv::show::modal', 'assign_to_group_' + this.account.gpk)
    },
    createMgr2OU() {
      this.db_editor_function = 'create'
      this.db_editor_old_data = {}
      this.db_editor_presets = {mgr_login_name: this.account.login_name}
      this.db_editor_object_fq_name = 'cntl.mgr2ou'
      this.db_editor_non_optionals_order = ['ou_short_name'].concat(this.default_order)
      this.$root.$emit('bv::show::modal', 'assign_to_group_' + this.account.gpk)
    },
  },
  computed: {
    own_groups() {
      return this.get_groups_of_mgr(this.account.login_name)
    },
    mgr_ous() {
      return this.get_ous_of_mgr(this.account.login_name)
    },
    mgr_sub_accounts() {
      return this.get_sub_account_of_mgr(this.account.login_name)
    },
    can_impersonate() {
      return this.check_can_impersonate(this.account.login_name)
    },
    group_list_fields() {
      return [
        {
          key: 'name',
          label: this.$t('components.group_card.group_name'),
          sortable: false
        },
        {
          key: 'ou_short_name',
          label: this.$tc('system.organizational_unit', 1),
          sortable: false
        },
        {
          key: 'description',
          label: this.$t('system.description'),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    },
    ous_list_fields() {
      return [
        {
          key: 'ou_short_name',
          label: this.$tc('system.organizational_unit', 1),
          sortable: false
        },
        {
          key: 'actions',
          label: this.$tc('system.action', 2),
          sortable: false
        }
      ]
    }
  }
}
</script>

<style scoped>

</style>
