<template>
  <div class="loading-container">
    <div v-if="loaded">
      <slot/>
    </div>
    <div v-else class="text-center my-3">
      <b-spinner variant="primary"/>
    </div>
  </div>
</template>

<script>
/**
 * @display_name Loading Placeholder
 * @description Placeholder for loading data, shows a spinner until data is loaded, data can be an array or a single object
 */
export default {
  name: 'LoadingPlaceholder',
  props: {
    data: {
      default() {
        return null
      }
    },
    init_loaded: {
      default() {
        return false
      },
      type: Boolean
    }
  },
  data() {
    return {
      loaded: this.init_loaded
    }
  },
  watch: {
    data(val) {
      if (val === null) {
        this.loaded = false
      } else {
        let loaded = true
        if (Array.isArray(val)) {
          for (const item of val) {
            if (item === null) {
              loaded = false
            }
          }
        }
        this.loaded = loaded
      }
    }
  }
}
</script>

<style scoped>

</style>
