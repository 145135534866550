<template>
  <div>
    <h1 v-if="!single_account">{{$tc('components.svc_card.svc_account', 2)}}</h1>
    <h1 v-else>{{ $tc('components.svc_card.svc_account', 1) }}: {{ selected_account_name }}</h1>

    <b-checkbox :value="true" :unchecked-value="false" v-model="only_own_svc" switch v-if="!single_account">
      {{ $t('views.cntl.mgr.only_own_svc') }}
    </b-checkbox>
    <hr>
    <b-button v-if="!single_account" style="margin-bottom: 20px" block variant="outline-success"
              id="button-create-svc-account" @click="createSVCAccount()">
      <netvs-icon icon="create"/>
      {{ $t('views.cntl.mgr.create_svc_account') }}
    </b-button>
    <Loading :data="[accounts]">
      <FilterInput v-model="filter" v-if="!single_account"/>
      <template v-for="svc in accounts.filter(a => {
        return (((!a.is_sub) && (!only_own_svc || check_can_impersonate(a.login_name)))
           && filter_matches(a)) || single_account
      })">
        <div v-bind:key="svc.login_name + '_uid'">
          <SVCAccountCard :account="svc"
                          :showEVLog="showEVLog"
                          :get_groups_of_mgr="get_groups_of_mgr"
                          :get_ous_of_mgr="get_ous_of_mgr"
                          :get_sub_account_of_mgr="get_sub_account_of_mgr"
                          :check_can_impersonate="check_can_impersonate"
          ></SVCAccountCard>
        </div>
      </template>
    </Loading>
    <CreateSVCAccount modal_id="create_svc_account"></CreateSVCAccount>
    <EVLogViewer v-if="ev_account" modal_id="svc-evlog"
                 :title="$tc('components.svc_card.svc_account', 1) + ' ' + ev_account.login_name"
                 refobj_id_field="gpk"
                 :refobj_id_value="ev_account.gpk" ref_obj_fq="cntl.mgr"></EVLogViewer>
  </div>
</template>

<script>
import svc_accountService from '@/api-services/svc_account.service'
import SVCAccountCard from '@/components/SVCAccountCard.vue'
import apiutil from '@/util/apiutil'
import EVLogViewer from '@/components/EVLogViewer.vue'
import groupsService from '@/api-services/groups.service'
import Loading from '@/components/Loading.vue'
import FilterInput from '@/components/FilterInput.vue'
import CreateSVCAccount from '@/db-editors/CreateSVCAccount.vue'

export default {
  name: 'SVCAccounts',
  components: {CreateSVCAccount, FilterInput, Loading, EVLogViewer, SVCAccountCard},
  data() {
    return {
      only_own_svc: true,
      filter: '',
      svcAccounts: [],
      svc_mgr_groups: {},
      groups: {},
      svc_mgr_ous: {},
      svc_sub_accounts: {},
      ev_account: undefined,
      mgr_own_groups: [],
      selected_account_name: undefined,
    }
  },
  async created() {
    if (!this.$store.state.user) {
      return
    }
    if ('account_name' in this.$route.params) {
      this.selected_account_name = this.$route.params.account_name
    } else {
      this.selected_account_name = undefined
    }
    const own_groups = await groupsService.list_for_mgr(this.$store.state, this.$store.state.impersonate_user || this.$store.state.user?.login_name)
    this.mgr_own_groups = own_groups.data.group_list.map(g => g.name)

    const list = await svc_accountService.list(this.$store.state, this.selected_account_name)
    this.svcAccounts = list.data.mgr_list
    this.svc_mgr_groups = apiutil.dict_of_lists_by_value_of_array(list.data.mgr2group_list, 'mgr_login_name')
    this.groups = apiutil.dict_by_value_of_array(list.data.group_list, 'name')
    this.svc_mgr_ous = apiutil.dict_of_lists_by_value_of_array(list.data.mgr2ou_list, 'mgr_login_name')
    this.svc_sub_accounts = apiutil.dict_of_lists_by_value_of_array(list.data.mgr_list.filter(m => m.is_sub), 'parent_login_name')
  },
  computed: {
    accounts() {
      return this.svcAccounts
    },
    single_account() {
      return this.selected_account_name !== undefined
    }
  },
  methods: {
    showEVLog(account) {
      this.ev_account = account
      this.$root.$nextTick(() => {
        this.$root.$emit('bv::show::modal', 'svc-evlog')
      })
    },
    get_groups_of_mgr(mgr_name) {
      let result = []
      if (this.svc_mgr_groups[mgr_name] !== undefined) {
        result = this.svc_mgr_groups[mgr_name].map(g => this.groups[g.group_name]).filter(g => g !== undefined)
      }
      return result
    },
    get_ous_of_mgr(mgr_name) {
      return this.svc_mgr_ous[mgr_name] || []
    },
    get_sub_account_of_mgr(mgr_name) {
      return this.svc_sub_accounts[mgr_name] || []
    },
    check_can_impersonate(svc_account_login_name) {
      return this.get_groups_of_mgr(svc_account_login_name).map(g => g?.name || false).filter(g => this.mgr_own_groups.includes(g)).length > 0
    },
    createSVCAccount() {
      this.$root.$emit('bv::show::modal', 'create_svc_account')
    },
    filter_matches(svc) {
      const filter_lower = this.filter.toLowerCase()
      return filter_lower === '' ||
          svc.login_name.toLowerCase().includes(filter_lower) ||
          (svc.description || '').toLowerCase().includes(filter_lower) ||
          (svc.svc_id || '').toLowerCase().includes(filter_lower)
    }
  }
}
</script>

<style scoped>

</style>
