import TransactionService from './transaction.service'
import apiutil from '@/util/apiutil'

export default {
  list_own_for_sub_mgr (config, mgr) {
    const ta = [
      { name: 'cntl.mgr2group.list', old: { mgr_login_name_list: [mgr], sorting_params_list: ['group_name asc', 'mgr_login_name asc'] }, idx: 'tmp_mgr2group_list'},
      { name: 'cntl.group.list', inner_join_ref: { tmp_mgr2group_list: 'default' }, old: { is_admin_or_is_own: true, sorting_params_list: ['name'] } },
      { name: 'nd.bcd2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'bcd_name asc'] }},
      { name: 'dns.fqdn2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name', 'fqdn_value'] } },
      { name: 'cntl.mgr2group.list', inner_join_ref: {group_list: 'default'}, idx: 'mgr2group_list'},
      { name: 'cntl.mgr.list', inner_join_ref: { mgr2group_list: 'default'} },
      { name: 'nd.bcd.list', inner_join_ref: { bcd2group_list: 'default'} }
    ]
    return TransactionService.execute(config, ta)
  },
  list_for_sub_mgr (config, mgr) {
    const ta = [
      { name: 'cntl.mgr2group.list', old: { mgr_login_name_list: [mgr], sorting_params_list: ['group_name asc', 'mgr_login_name asc'] }, idx: 'tmp_mgr2group_list'},
      { name: 'cntl.group.list', inner_join_ref: { tmp_mgr2group_list: 'default' }, old: { sorting_params_list: ['name'] } },
      { name: 'nd.bcd2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'bcd_name asc'] }},
      { name: 'dns.fqdn2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name', 'fqdn_value'] } },
      { name: 'cntl.mgr2group.list', inner_join_ref: {group_list: 'default'}, idx: 'mgr2group_list'},
      { name: 'cntl.mgr.list', inner_join_ref: { mgr2group_list: 'default'} },
      { name: 'nd.bcd.list', inner_join_ref: { bcd2group_list: 'default'} }
    ]
    return TransactionService.execute(config, ta)
  },
  list_for_mgr (config, mgr) {
    const ta = [
      { name: 'cntl.mgr2group.list', old: { mgr_login_name_list: [mgr], sorting_params_list: ['group_name asc', 'mgr_login_name asc'] }},
      { name: 'cntl.group.list', inner_join_ref: { mgr2group_list: 'default' }, old: { is_admin_or_is_own: true, sorting_params_list: ['name'] } }
    ]
    return TransactionService.execute(config, ta)
  },
  list (config, own) {
    const ta = [
      { name: 'cntl.group.list', old: apiutil.denullify_dict({ is_admin_or_is_own: own, sorting_params_list: ['name'] }) },
      { name: 'cntl.mgr2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'mgr_login_name asc'] }},
      { name: 'nd.bcd2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'bcd_name asc'] }},
      { name: 'dns.fqdn2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name', 'fqdn_value'] } },
      { name: 'cntl.mgr.list', inner_join_ref: { mgr2group_list: 'default'} },
      { name: 'nd.bcd.list', inner_join_ref: { bcd2group_list: 'default'} }
    ]
    return TransactionService.execute(config, ta)
  },
  get_by_name (config, name) {
    const ta = [
      { name: 'cntl.group.list', idx: 'main_groups', old: { name: name } },
      { name: 'cntl.group.list', idx: 'subgroups', old: { is_sub: true }, inner_join_ref: {main_groups: 'api_fkey_cntl_group_sub'} },
      { name: 'cntl.group.list', idx: 'group_list', union_join_ref: {main_groups: 'self', subgroups: 'self'} },
      { name: 'cntl.mgr2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'mgr_login_name asc'] }},
      { name: 'nd.bcd2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name asc', 'bcd_name asc'] }},
      { name: 'dns.fqdn2group.list', inner_join_ref: { group_list: 'default'}, old: { sorting_params_list: ['group_name', 'fqdn_value'] } },
      { name: 'cntl.mgr.list', inner_join_ref: { mgr2group_list: 'default'} },
      { name: 'nd.bcd.list', inner_join_ref: { bcd2group_list: 'default'} },
    ]
    return TransactionService.execute(config, ta)
  }
}
