import TransactionService from './transaction.service'

export default {
  getByFK(config, ref_obj_fq, refobj_id_field, refobj_id_value, top_n) {
    const old = {}
    old[refobj_id_field] = refobj_id_value
    const ta = [
      {
        idx: 'base_select',
        name: ref_obj_fq + '.list',
        old: old
      },
      {
        name: 'evlog.record.list',
        old: {
          top_n_from_newest: true,
          top_n: top_n,
          sorting_params_list: ['ta_timestamp desc']
        },
        inner_join_ref: {base_select: null}
      },
      {name: 'evlog.record_type.list'}
    ]
    return TransactionService.execute(config, ta)
  }
}
